'use client'

import { Col, Grid, Row } from 'antd'
import { useSearchParams } from 'next/navigation'
import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react'

import useAuth from '@/hooks/useAuth'

import ConversationsDrawer from '@/components/Admin/Conversations/ConversationsDrawer'
import ConversationsList from '@/components/Admin/Conversations/ConversationsList'

import { DrawerContext } from '@/context/conversationDrawer/drawer.context'
import { generateUUID } from '@/utils'
import { cn } from '@/utils/clsx'

import { Conversation } from '@/types/chatbot'

const { useBreakpoint } = Grid

interface ChatLayoutProps extends PropsWithChildren {
  conversations: Conversation[]
  mutateConversations: () => void
  setConversationsSize: React.Dispatch<React.SetStateAction<number>>
  noMoreData?: boolean
  className?: string
}

const ChatLayout: React.FC<ChatLayoutProps> = ({
  conversations,
  mutateConversations,
  setConversationsSize,
  noMoreData,
  className,
  children,
}) => {
  const screens = useBreakpoint()
  const searchParams = useSearchParams()
  const { user } = useAuth()
  const [firstLoad, setFirstLoad] = useState(true)
  const { setSelectedConversation, selectedConversation } =
    useContext(DrawerContext)

  useEffect(() => {
    if (searchParams && firstLoad) {
      setSelectedConversation(searchParams.get('conversationId') as string)
      setFirstLoad(false)
    }
  }, [searchParams])

  useEffect(() => {
    if (!selectedConversation) {
      setSelectedConversation(generateUUID())
    }
  }, [])

  return (
    <>
      <Row>
        {user?.email && (
          <Col
            span={6}
            className={cn(
              'hide-scrollbar !hidden w-full lg:!block h-screen',
              className
            )}
          >
            {/* when screen size > lg; then show block on the left side */}
            <ConversationsList
              conversations={conversations}
              mutateConversations={mutateConversations}
              setConversationsSize={setConversationsSize}
              noMoreData={noMoreData}
            />
          </Col>
        )}
        <Col span={screens.lg && user?.email ? 18 : 24} className='w-full'>
          {children}
        </Col>
      </Row>
      <ConversationsDrawer
        conversations={conversations}
        mutateConversations={mutateConversations}
        setConversationsSize={setConversationsSize}
        noMoreData={noMoreData}
      />
    </>
  )
}

export default ChatLayout
